
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import WithModel from '@/mixins/withModel';
import moment from 'moment';

@Options({})
export default class monthNavigator extends mixins(WithModel) {

    periodo: string = "";

    created(){
        this.localValue = moment(new Date()).startOf('month').toISOString();
        //this.periodo = moment(this.localValue).locale('it').format("MMMM YYYY");
    }

    prev(){
        this.localValue = moment(this.localValue).subtract(1, 'month').startOf('month').toISOString();
        //this.periodo = moment(this.localValue).locale('it').format("MMMM YYYY");
        this.emitModel(this.localValue);
    }

    next(){
        this.localValue = moment(this.localValue).add(1, 'month').startOf('month').toISOString();
        //this.periodo = moment(this.localValue).locale('it').format("MMMM YYYY");
        this.emitModel(this.localValue);
    }

    get canNext(){
        return moment(this.localValue).startOf('month').unix() < moment().startOf('month').unix();
    }

}
