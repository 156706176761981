import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      class: "form-control me-2",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.giorno) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.composeAndEmit && _ctx.composeAndEmit(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.giorniList, (item, i) => {
        return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(item), 1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.giorno]
    ]),
    _withDirectives(_createElementVNode("select", {
      class: "form-control mx-1",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mese) = $event)),
      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.composeAndEmit && _ctx.composeAndEmit(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mesiList, (item, i) => {
        return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(item), 1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.mese]
    ]),
    _withDirectives(_createElementVNode("select", {
      class: "form-control ms-2",
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.anno) = $event)),
      onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.composeAndEmit && _ctx.composeAndEmit(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anniList, (item, i) => {
        return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(item), 1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.anno]
    ])
  ]))
}