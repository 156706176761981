import moment from 'moment';
import * as OM from '@/Model';

export default {

    date(value: any, _format: string = "YYYY MMMM DD") {
        if (value) {
            return moment(value).format(_format);
        }
    },

    address(value: OM.Address){
        let ris = `${value.via || ''}, ${value.civico || ''}`;
        if(value.scalaPianoInterno){
            ris += `${value.scalaPianoInterno}`
        }
        ris += `, ${value.citta || ''}(${value.provincia || ''}), ${value.cap || ''},  ${value.nazione || ''}`;

        return ris;
    },

    timespan(value: string) {
        return value.substr(0, 5);
    },

    currency(value: any, decimals: number = 2) {
        if(isNaN(parseFloat(value))){
            return value;
        }
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    underscore(value: string){
        let ris = value;
        ris = ris.replaceAll(", ", "_");
        ris = ris.replaceAll(" ", "_");
        ris = ris.replaceAll("-", "_");
        ris = ris.replaceAll(",", "_");
        ris = ris.replaceAll("'", "_");
        ris = ris.replaceAll(".", "");
        return ris;
    },
    
    tempofromminuti(value: number) {
        if(!value)
            return '-'
            
        let minutiRimasti = value % 60;
        let ore = Math.floor(value / 60);
        let giorni = Math.floor(ore / 24);
        let oreMod = Math.floor(ore % 24);
        let mesi = Math.floor(giorni / 30);
        let giorniMod = Math.floor(giorni % 30);
        let anni = Math.floor(mesi / 12);
        let mesiMod = Math.floor(mesi % 12);

        let result = "";
        if(anni > 0){
            result += anni + ' ann' + (anni == 1 ? 'o' : 'i');
            if(mesi > 0){
                result += ' e ' + mesiMod + ' mes' + (mesiMod == 1 ? 'e' : 'i');
            } 
        } else if(mesi > 0) {
            result += mesi + ' mes' + (mesi == 1 ? 'e' : 'i');
            if(giorniMod > 0){
                result += ' e ' + giorniMod + ' giorn' + (giorniMod == 1 ? 'o' : 'i');
            } 
        } else if(giorni > 0) {
            result += giorni + ' giorn' + (giorni == 1 ? 'o' : 'i');
            if(oreMod > 0){
                result += ' e ' + oreMod + ' or' + (oreMod == 1 ? 'a' : 'e');
            } 
        } else if(ore > 0) {
            result += ore + ' or' + (ore == 1 ? 'a' : 'e') + ' e ' + minutiRimasti + ' minut' + (minutiRimasti == 1 ? 'o' : 'i');
        }
        return result;
    }

}
