import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "lowTitle"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { value: "" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_localized_input = _resolveComponent("localized-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Prefisso",
              text: "Prefisso"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.prefix) = $event)),
            class: "form-control"
          }, [
            _createElementVNode("option", _hoisted_5, [
              _createVNode(_component_localized_text, {
                localizedKey: "Seleziona",
                text: "Seleziona"
              })
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prefixList, (item, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: '+' + item.prefix
              }, _toDisplayString(item.nazione) + " + " + _toDisplayString(item.prefix), 9, _hoisted_6))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.localValue.prefix]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_localized_input, {
          labelKey: "Numero",
          labelValue: "Numero",
          placeholderKey: "Numero",
          placeholderValue: "Numero",
          modelValue: _ctx.localValue.number,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.number) = $event))
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}