import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/home',
            name: 'home',
            component: () => import("./views/home.vue"),
            meta: {
                hasBar: true,
                closeAppModal: true
            }
        },
        {
            path: '/datiPersonali',
            name: 'datiPersonali',
            component: () => import("./views/datiPersonali.vue"),
        },
        {
            path: '/badge',
            name: 'badge',
            component: () => import("./views/badge.vue"),
        },
    ];
};