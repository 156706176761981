
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { ModalServices } from '@/services/ModalServices';
import { StorageServices } from './services/StorageServices';
import { CommonServices } from './services/CommonServices';
import { LoginServices } from './services/LoginServices';
import router from './router';
import store from './store';
import { BaseUserClient, LeadClient, LocalizedClient, LoginClient } from './services/Services';
import { appMode } from './config';
import { LocalizationServices } from './services/LocalizationServices';

@Options({})
export default class App extends Vue {

    loading: boolean = true;
    appMode: string = appMode

    mounted(){
    
        document.addEventListener("deviceready", () => {
            if(Keyboard){
                if(device.platform == "iOS"){
                    Keyboard.hideFormAccessoryBar(false);
                    // Keyboard.setResizeMode('body');
                }
            }

            document.addEventListener("backbutton", () => {
                if(store.state.backButtonFunction){
                    store.state.backButtonFunction();
                } else {
                    if(this.$opModal.modals().length > 0){
                        this.$opModal.closeLast();
                        return;
                    }

                    if(this.$route.meta.closeAppModal){
                        ModalServices.confirmModal(
                            '',
                            LocalizationServices.GetLocalizedValue('Vuoi chiudere Nutrilab?', 'Vuoi chiudere Nutrilab?'),
                            LocalizationServices.GetLocalizedValue('Si', 'Si'),
                            LocalizationServices.GetLocalizedValue('No', 'No'),
                            () => {
                                (<any>window.navigator).app.exitApp();
                            }
                        )
                    } else {
                        this.$router.back();
                    }
                }
            }, false);

            universalLinks.subscribe(null, (eventData) => {
                // do some work
                console.log(eventData);
                if(eventData.hash){
                    this.$router.replace(eventData.hash);
                }
            });

            
            window.addEventListener('keyboardWillShow', (event: any) => {
                document.body.classList.add('keyboardopen');
                // if(device.platform == "Android"){
                //     let root = document.documentElement;
                //     root.style.setProperty('--keyboardHeight', (event).keyboardHeight +  "px");
                // }
            });
            window.addEventListener('keyboardWillHide', (event) => {
                document.body.classList.remove('keyboardopen');
                let pageContainer = document.querySelector('.appPageContainer')
                if(pageContainer.scrollHeight <= window.innerHeight){
                    pageContainer.scrollTop = 0;
                }

                // if(device.platform == "Android"){
                //     let root = document.documentElement;
                //     root.style.setProperty('--keyboardHeight', "0px");
                // }
            });

        }, false);

        if(this.$route.name == 'change_password')
            return;

        let proms: any[] = [
            LocalizedClient.getAll(),
            LocalizedClient.getEnabledLanguages(),
        ];

        Promise.all(proms)
        .then(xs => {
            store.state.consts.localizedValues = xs[0];
            store.state.consts.enabledLanguages = xs[1];
            if(!store.state.selectedLanguage){
                store.state.selectedLanguage = xs[1][0];
            }
            
            let refreshPromise = [];
            let loginData = StorageServices.getLoginData();
            if(loginData){
                refreshPromise.push(LoginClient.refreshLogin());
            } else {
                refreshPromise.push(Promise.resolve());
            }
            Promise.all(refreshPromise)
            .then(x => {

                if(x[0]){
                    LoginServices.loginCallback(x[0])
                    .then(x => {
                        this.loading = false;
                        if(x){
                            if(this.$route.name == 'begin'){
                                this.$router.push("/home");
                            }
                        }
                    })
                } else {
                    // this.$router.push("/");
                    this.loading = false;
                }
            })
            .catch(err => {
                this.loading = false;
            })
        })
        .catch(err => {
            this.loading = false;
        })

        ModalServices.setOpModal(this.$opModal);
    }

}
