import * as OM from '@/Model';
import * as VM from '@/viewModel';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getUserIdentifier() {
        return this.readFromLocalStorage<string>('userIdentifier');
    }
    setUserIdentifier(value: string){
        window.localStorage.setItem('userIdentifier', JSON.stringify(value));
    }
    
    SetLanguage(language: string) {
        window.localStorage.setItem('locale', language);
    }
    GetLanguage() {
        let data = window.localStorage.getItem('locale');
        if(!data){
            return '';
        } else {
            return data;
        }
    }

    
    setOpTableFilters(key: string, filters: any){
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }
    
    setPrenotazioneData(data: VM.PrenotazioneDataVm){
        window.localStorage.setItem('PrenotazioneDataVm', JSON.stringify(data));
    }
    getPrenotazioneData(){
        return this.readFromLocalStorage<VM.PrenotazioneDataVm>('PrenotazioneDataVm');
    }
    
    setLoginData(loginData: OM.LoginData){
        window.localStorage.setItem('_loginData', JSON.stringify(loginData));
    }
    getLoginData(){
        return this.readFromLocalStorage<OM.LoginData>('_loginData');
    }
    
    setNonFumatore(NonFumatore: boolean){
        window.localStorage.setItem('_NonFumatore', JSON.stringify(NonFumatore));
    }
    getNonFumatore(){
        return this.readFromLocalStorage<boolean>('_NonFumatore');
    }
}

export let StorageServices = new _StorageServices();