import { createApp } from 'vue'
import App from '@/App.vue'
import router from './router'
import store from './store'
import modalPlugin from './plugins/modalPlugin';
import DIRECTIVES from '@/directives';
import FILTERS from '@/filters';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/transitions.css';
import '@/css/op-table.css';
import '@/css/style.css';
import 'swiper/css';

import { register } from 'swiper/element/bundle';
register();


//toastr
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
window.toastr = toastr;

import moment from 'moment';
import { LocalizationServices } from './services/LocalizationServices';
moment.locale("IT-it");

import 'chartjs-adapter-moment';


const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin);

for(let prop in DIRECTIVES){
    app.directive(prop, DIRECTIVES[prop])
}

app.config.globalProperties.$filters = FILTERS;
app.config.globalProperties.$localizationService = LocalizationServices;

const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})


function delegate(el, evt, sel, handler) {
    el.addEventListener(evt, function(event) {
        var t = event.target;
        while (t && t !== this) {
            if (t.matches(sel)) {
                handler.call(t, event);
            }
            t = t.parentNode;
        }
    });
}

// delegate(window.document.body, "click", "a", (event) => {
//     if(event.target.target == '_blank'){
//         store.state.openBrowser(event);
//     }
// })

window.handleOpenURL = (url: string) => {
    // setTimeout(() => {
    //     let hashIndex = url.indexOf("#");
    //     if(hashIndex > -1){
    //         let navTo = url.substring(hashIndex + 1);
    //         router.push(navTo);
    //     }
    // }, 100)
}

app.mount('#app');
