import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import begin from '@/views/begin';
import home from '@/views/home';
import provvigioni from '@/views/provvigioni';
import team from '@/views/team';
import wallet from '@/views/wallet';
import assistenza from '@/views/assistenza';
import clienti from '@/views/clienti';
import estrattoConto from '@/views/estrattoConto';
import acquisti from '@/views/acquisti';
import shop from '@/views/shop';
import questionario from '@/views/questionario';
import prenotazioni from '@/views/prenotazioni';
import pianoAlimentare from '@/views/pianoAlimentare';
import profile from '@/views/profile';
import faq from '@/views/faq';
import chat from '@/views/chat';
import { StorageServices } from '@/services/StorageServices';
import ricettario from '@/views/ricettario';
import storico from '@/views/storico';
import corsi from '@/views/corsi';
import news from '@/views/news';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/',
        component: () => import("@/views/layout.vue"),
        children: [
            ...begin.routes(),
            ...home.routes(),
            ...provvigioni.routes(),
            ...team.routes(),
            ...assistenza.routes(),
            ...chat.routes(),
            ...wallet.routes(),
            ...clienti.routes(),
            ...questionario.routes(),
            ...estrattoConto.routes(),
            ...acquisti.routes(),
            ...shop.routes(),
            ...prenotazioni.routes(),
            ...pianoAlimentare.routes(),
            ...profile.routes(),
            ...faq.routes(),
            ...ricettario.routes(),
            ...storico.routes(),
            ...corsi.routes(),
            ...news.routes()
        ]
    },
]

// let scrollerSavedPositionList: { routeName: string, top: number}[] = [];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scroller = document.querySelector('#app .layoutPageContainer');

        if(savedPosition)
            return savedPosition;
        else
            scroller.scrollTop = 0;

        // if(scroller){
        //     let scrollerSavedPositionIndex = scrollerSavedPositionList.findIndex(x => x.routeName == to.fullPath);
        //     if (scrollerSavedPositionIndex > -1 && savedPosition) {
        //         console.log(scrollerSavedPositionList[scrollerSavedPositionIndex].top);
        //         scroller.scrollTop = scrollerSavedPositionList[scrollerSavedPositionIndex].top;
        //         scrollerSavedPositionList.splice(scrollerSavedPositionIndex, 1);
        //         scrollerSavedPositionList.splice(scrollerSavedPositionList.findIndex(x => x.routeName == from.fullPath), 1);
        //     } else {
        //         scroller.scrollTop = 0;
        //     }
        // }
    }
})


router.beforeEach((to, from, next) => {
    let loginData = StorageServices.getLoginData();
    if(!to.meta.allowAnonymous && !loginData){
        router.push('login' + '?redirect=' + to.fullPath);
        return;
    }

    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
    
    // let scroller = document.querySelector('#app .layoutPageContainer');
    // if(scroller){
    //     scrollerSavedPositionList.push({
    //         routeName: from.fullPath,
    //         top: scroller.scrollTop
    //     })
    // }
})

router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default router
