
import * as OM from '@/Model'
import { NazioneClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class personalData extends Vue {

    @Prop() item: OM.PersonalData;

    nazioni: OM.Nazione[] = [];

    created(){
        // NazioneClient.getEnabled()
        // .then(x => {
        //     this.nazioni = x;
        // })
    }
}
