import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_payment_data = _resolveComponent("user-payment-data")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_fiscal_data = _resolveComponent("fiscal-data")!
  const _component_contact_data = _resolveComponent("contact-data")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_user_payment_data, {
      modelValue: _ctx.data,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_personal_data, {
      item: _ctx.localValue.personalData
    }, null, 8, ["item"]),
    _createVNode(_component_fiscal_data, {
      modelValue: _ctx.localValue.fiscalData,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.fiscalData) = $event)),
      user: _ctx.$store.state.user
    }, null, 8, ["modelValue", "user"]),
    _createVNode(_component_contact_data, {
      item: _ctx.localValue.contactData
    }, null, 8, ["item"])
  ]))
}