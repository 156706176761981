
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class localizedInput extends mixins(WithModel) {

    @Prop() tabIndex;
    @Prop() labelKey;
    @Prop() labelValue;
    @Prop() labelPrevText;
    @Prop() labelAfterText;
    @Prop() placeholderKey;
    @Prop() placeholderValue;
    @Prop() placeholderPrevText;
    @Prop() placeholderAfterText;
    @Prop({ default: "text" }) type: string;
    @Prop({ default: 1 }) step: number;
    @Prop({ default: null }) min: number;
    @Prop({ default: null }) max: number;
    @Prop({ default: false }) readonly: boolean;

    showPassword: boolean = false;

    changeModel(){
        this.emitModel(this.localValue);
    }

    clickInput(){
        (this.$refs.input as any).focus();
    }

    // changeShowPassword(){
    //     this.showPassword = !this.showPassword;

    //     if(this.showPassword)
    //         (<any>this.$refs.input).type = "text";
    //     else
    //         (<any>this.$refs.input).type = "password";

    // }
}
