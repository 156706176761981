
import WithModel from "@/mixins/withModel";
import { nextTick } from "vue";
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from "vue-property-decorator";
import FTS from '@/filters'

@Options({})
export default class sesso extends mixins(WithModel) {

    setValue(value: string){
        this.localValue = value;
        this.emitModel(value);
    }

};
