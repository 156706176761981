import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/newsFeed',
            name: 'newsFeed',
            component: () => import("./views/newsFeed.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/newsFeed/:identifier',
            name: 'newsFeedDetail',
            component: () => import("./views/newsFeedDetail.vue"),
            meta: {
                hasBar: false
            }
        }
    ];
};