import * as Enum from '@/const';
import * as OM from '@/Model';
import moment from 'moment';
import store from './store';
import CodiceFiscale from 'codice-fiscale-js';

export function JsonClone(item){
    return JSON.parse(JSON.stringify(item));
}

export function getInizioTrimestre(mese: number){
    mese += 1;
    let currentYear = new Date().getFullYear().toString();
    if(mese >= 1 && mese <= 3){
        return moment(`${currentYear}-01-1`);
    } else if(mese >= 4 && mese <= 6) {
        return moment(`${currentYear}-04-1`);
    } else if(mese >= 7 && mese <= 9) {
        return moment(`${currentYear}-07-1`);
    } else if(mese >= 10 && mese <= 12)  {
        return moment(`${currentYear}-10-1`);
    }
}

export function getFineTrimestre(mese: number){
    mese += 1;
    let currentYear = new Date().getFullYear().toString();
    if(mese >= 1 && mese <= 3){
        return moment(`${currentYear}-01-01`).add(3, 'months').subtract(1,'second');
    } else if(mese >= 4 && mese <= 6) {
        return moment(`${currentYear}-04-01`).add(3, 'months').subtract(1,'second');
    } else if(mese >= 7 && mese <= 9) {
        return moment(`${currentYear}-07-01`).add(3, 'months').subtract(1,'second');
    } else if(mese >= 10 && mese <= 12)  {
        return moment(`${currentYear}-10-01`).add(3, 'months').subtract(1,'second');
    }
}

export function checkIfRichiestaPagamentoPossible(user: OM.Collaboratore | OM.UpdateCollaboratoreVm){
    return user.fiscalData.codiceFiscale && 
        user.fiscalData.dataNascita &&
        user.fiscalData.luogoNascita &&
        user.fiscalData.fiscalAddress.citta &&
        user.fiscalData.fiscalAddress.provincia &&
        user.fiscalData.fiscalAddress.cap &&
        user.fiscalData.fiscalAddress.via &&
        user.fiscalData.fiscalAddress.civico &&
        user.fiscalData.fiscalAddress.nazione &&
        user.paymentData.bankAccountData.iban &&
        user.paymentData.bankAccountData.intestatario
}

export function checkIfAddressCompleted(input: OM.Address){
    return input && 
        input.citta &&
        input.provincia &&
        input.cap &&
        input.via &&
        input.civico &&
        input.nazione
}

type Gender = "F" | "M";
export function CalcolaCodFisc(name: string, surname: string, gender: Gender, day: number, month: number, year: number, birthplace, birthplaceProvincia){
    let cf = new CodiceFiscale({
        name: name,
        surname: surname,
        gender: gender,
        day: day,
        month: month,
        year: year,
        birthplace: birthplace,
        birthplaceProvincia: birthplaceProvincia
    });
    return cf;
}

export function CodFiscInverso(input: string){
    return CodiceFiscale.computeInverse(input);
}