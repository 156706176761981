
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { MisuraClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class misurazioneModal extends Vue {

    @Prop() userIdentifier: string;
    @Prop() misureInseriteCb: () => void;

    misura: OM.Misura = new OM.Misura();

    get canSave(){
        return this.misura.addome && this.misura.vita && this.misura.fianchi && this.misura.peso;
    }

    save(){
        if(!this.canSave)
            return;

        this.misura.cliente = {
            identifier: store.state.user.identifier,
            name: store.state.user.personalData.completeName
        }
        this.misura.data = new Date().toISOString();
        MisuraClient.save(this.misura)
        .then(x => {
            if(this.misureInseriteCb){
                this.misureInseriteCb();
            }
            this.$emit('close');
        })
    }

}
