
import WithModel from '@/mixins/withModel';
import * as OM from '@/Model'
import { NazioneClient } from '@/services/Services';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class telephone extends mixins(WithModel) {

    @Prop() label: string;

    prefixList: OM.NumberNazione[] = [];

    created(){
        if(!this.modelValue){
            this.localValue = new OM.Telephone();
            this.emitModel(this.localValue);
        }
        NazioneClient.getPrefissiTelefonici()
        .then(x => {
            this.prefixList = x;
        })
    }

}
