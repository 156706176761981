
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";


@Options({})
export default class ImgPreviewModal extends Vue {

    @Prop() imgPreview: string;
    @Prop() okCb: any;
    
    ok() {
        this.okCb();
        this.$emit('close');
    }
    
}

