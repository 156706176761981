import * as OM from '@/Model';
import { IModalPlugin } from '@/plugins/modalPlugin/modalPlugin';
import AlertModal from '@/components/modals/alertModal.vue';
import ConfirmModal from '@/components/modals/confirmModal.vue';
import InputModal from '@/components/modals/inputModal.vue';
import successModal from '@/components/modals/successModal.vue';
import errorModal from '@/components/modals/errorModal.vue';
import { LocalizationServices } from './LocalizationServices';

class _ModalServices {
    
    $opModal: any;
    setOpModal(input: IModalPlugin){
        this.$opModal = input;
    }

    successModal(text: string, okText?: string, okCallback?: () => void ){
        okText = okText || "OK";
        this.$opModal.show(successModal, {
            text,
            okText,
            okCallback
        })
    }

    alertModal(title: string, text: string, okText?: string, okCallback?: () => void ){
        okText = okText || "OK";
        this.$opModal.show(AlertModal, {
            title,
            text,
            okText,
            okCallback
        })
    }

    confirmModal(title: string, text: string, okText?: string, koText?: string, okCallback?: () => void, koCallback?: () => void ){
        okText = okText || "OK";
        koText = koText || "Annulla";
        this.$opModal.show(ConfirmModal, {
            title,
            text,
            okText,
            koText,
            okCallback,
            koCallback
        })
    }

    inputModal(title: string, text: string, okText: string, koText: string, type: string, value: string, placeholder: string, okCallback: (input: string) => void, koCallback?: () => void ){
        okText = okText;
        koText = koText;
        placeholder = placeholder;
        if(!okText){
            okText = LocalizationServices.GetLocalizedValue("OK", "OK");
        }
        if(!koText){
            koText = LocalizationServices.GetLocalizedValue("Annulla", "Annulla");
        }
        if(!placeholder){
            placeholder = LocalizationServices.GetLocalizedValue("Scrivi qui", "Scrivi qui");
        }
        this.$opModal.show(InputModal, {
            title,
            text,
            okText,
            koText,
            type,
            value,
            placeholder,
            okCallback,
            koCallback
        })
    }

}

export let ModalServices = new _ModalServices();