
import * as OM from '@/Model';
import StripeSavedCards from './stripeSavedCards.vue';
// import { StripePublishableKey } from '@/config';
import Toggler from '@/components/toggler.vue';
import { StripeClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        StripeSavedCards,
        Toggler
    }
})
export default class stripeElements extends Vue {

    @Prop() userName: string;
    @Prop() intentCb: () => Promise<string>;
    @Prop({
        required: true
    }) stripeKey: string;

    completed: boolean = false;
    paying: boolean = false;
    elementsReady: boolean = false;

    stripe: any;
    elements: any;
    clientSecret: string = "";
    error: string = "";

    get isTestMode(){
        return this.stripeKey.indexOf('pk_test') > -1
    }

    created(){
        this.stripe = Stripe(this.stripeKey);
        this.intentCb()
        .then(x => {
            this.clientSecret = x;
            this.mountStripe();
        })
    }

    mountStripe(){
        const appearance = {
            theme: 'stripe',
        };
        this.elements = this.stripe.elements({ 
            appearance: appearance, 
            clientSecret: this.clientSecret 
        });

        const paymentElementOptions = {
            layout: "accordion",
        };

        const paymentElement = this.elements.create("payment", paymentElementOptions);
        paymentElement.mount(this.$refs.paymentElement);

        paymentElement.on('ready', () => {
            this.elementsReady = true;
        })

    }

    get canPay(){
        return !!this.clientSecret && this.elementsReady;
    }

    pay(){
        this.paying = true;

        this.stripe.confirmPayment({
            elements: this.elements,
            redirect: "if_required",
            confirmParams: {
                return_url: '',
            },
        })
        .then(result => {
            if(result.error){
                if (result.error.type === "card_error" || result.error.type === "validation_error") {
                    ModalServices.alertModal(
                        LocalizationServices.GetLocalizedValue("Errore", "Errore"), 
                        result.error.message
                    );
                } else {
                    ModalServices.alertModal(
                        LocalizationServices.GetLocalizedValue("Errore", "Errore"), 
                        "An unexpected error occurred."
                    );
                }
            } else {
                this.completed = true;
                this.$emit('complete', result.paymentIntent.id);
            }
        })
        .finally(() => {
            this.paying = false;
        });

    }

}

