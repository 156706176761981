import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/profile',
            name: 'profile',
            component: () => import("./views/profile.vue"),
            meta: {
                hasBar: true
            }
        },
    ];
};