import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Ciao! Abbiamo notato la tua assenza.",
        text: "Ciao! Abbiamo notato la tua assenza."
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Se dovessi avere bisogno di qualsiasi cosa apri un ticket o contatta la tua personal coach",
        text: "Se dovessi avere bisogno di qualsiasi cosa apri un ticket o contatta la tua personal coach"
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/assistenza/new')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Apri un ticket",
          text: "Apri un ticket"
        })
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-secondary mt-2",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setRead && _ctx.setRead(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Non mostrare più",
          text: "Non mostrare più"
        })
      ])
    ])
  ], 64))
}