import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl } from '@/config';
import router from '@/router';
import store from '@/store';
import { StorageServices } from './StorageServices';

class _CommonServices {
    baseUrl = baseUrl;

    showSpinner(){
        store.state.showSpinner++;
    };
    hideSpinner(){
        store.state.showSpinner--;
    };

    unauthorizedCb = () => {};
    
    private axios: AxiosInstance;
    get<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        url = replaceUrl(url, params);

        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.get<T>(url, req)
            .then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
            this.showSpinner()
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }
    uploadFileToUrl<T = any>(url: string, params: { [key: string]: any }, 
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                if(Array.isArray(params[key])){
                    if((<any[]>params[key]).every(x => x instanceof File)){
                        params[key].forEach(x => {
                            formData.append(key, x);
                        })
                    }
                } else {
                    formData.append(key, params[key]);
                }
            }
        }
        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                    onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if(_showSpinner)
            this.showSpinner()
        let prom = new Promise<T>((resolve, reject) => {
            Axios.post<T>(url, formData, config).then(x => {
                resolve(x.data);
            })
            .catch( err => {
                reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }
    setAuthToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }
    destroyToken() {
        this.axios.defaults.headers.common['Authorization'] = "";
    }

    constructor() {
        this.axios = Axios;
        this.axios.interceptors.response.use(function (response: any) {
            return response;
        }, function (error) {
            // if (error.response.status == 401) {
            //     this.unauthorizedCb();
            // } else {
            //     return Promise.reject(error);
            // }
            console.log(error.Message);
            if(error.response.state == 400) {
                alert("error 400");
            }
            if (error.response.status == 401) {
                if(router.currentRoute.value.path != '/')
                    router.push('login' + '?redirect=' + router.currentRoute.value.fullPath);
            } else {
                if(error.response.data && error.response.data.Level == "warning") {
                    // console.log("Warning: " + error.response.data.Message);
                }
                else if(error.response.data && error.response.data.Level == "validation") {
                    var data = error.response.data.Message;
                    // ToastedServices.Validation(data);
                    // console.log("Validation: " + data);
                }
                else if(error.response.data && error.response.data.Level == "error") {
                    // ToastedServices.Error(error.response.data.Message);
                    // console.log("Error in common: " + error.response.data.Exception);
                }
                if(error.response && error.response.data) {
                    return Promise.reject(error.response.data);
                }
                else {
                    return Promise.reject(error);
                }
            }
        });
        let loginData = StorageServices.getLoginData();
        if (loginData){
            this.setAuthToken(loginData.token);
        }
    }
}

function replaceUrl(url: string, params?: any): string {

    if(!params || url.indexOf("{") == -1)
        return url;

    var beginIndex = url.indexOf("{");
    var endIndex = url.indexOf("}");

    var property = url.substring(beginIndex + 1, endIndex);
    var value = params[property];

    url = url.substring(0, beginIndex) + value + url.substr(endIndex + 1);
    delete params[property];

    return replaceUrl(url, params);
}

export let CommonServices = new _CommonServices();