
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class confirmAccountDeletionModal extends Vue {

    @Prop() text: string;
    @Prop() okCb: () => void;

    timer = 5;
    interval = null;

    created(){
        this.interval = setInterval(() => {
            this.timer--;
            if(this.timer <= 0){
                clearInterval(this.interval);
            }
        }, 1000)
    }

    destroyed(){
        clearInterval(this.interval);
    }

    ok(){
        this.$emit('close');
        this.okCb();
    }

}

