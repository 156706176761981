
import WithModel from '@/mixins/withModel';
import * as OM from '@/Model'
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class contactData extends mixins(WithModel) {

    declare localValue: OM.ContactData;

}
