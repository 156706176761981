
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { InAppNotificationClient } from '@/services/Services';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class inAppNotifications extends Vue {

    avviso: OM.InAppNotification = null;

    created(){
        this.init();
    }

    init(){
        this.avviso = null;
        InAppNotificationClient.getNextByCliente(store.state.user.identifier)
        .then(x => {
            this.avviso = x;
        })
    }

}
