
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class bankAccountData extends Vue {

    @Prop() item: OM.BankAccountData;

}
