
import Component, { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop, Watch } from 'vue-property-decorator';

Options({})
export default class filePreviewer extends mixins(WithModel) {

    @Prop() preview: any;
    
    localPreview: any = null;
    previewType: string = "";
    imageFile: File = null;

    created(){
        this.localPreview = this.preview;
    }

    empty() {
        this.localPreview = null;
        this.emitModel(null);
    }

    emitData(ev){
        this.$emit('duration', ev.target.duration);
    }

    @Watch('preview')
    onPreviewChange(next, prev){
        if(typeof(next) == 'string'){
            this.localPreview = next;
        } else {
            this.localPreview = URL.createObjectURL(next);
        }
    }

    @Watch('modelValue')
    onModelChange(next, prev){
        if(!next){
            URL.revokeObjectURL(this.localPreview);
            return;
        }
        this.previewType = '';
        if(typeof(next) == 'string'){
            this.localPreview = next;
        } else {
            if(next.type.indexOf('video') > -1){
                this.previewType = 'video';
            } else if(next.type.indexOf('image') > -1){
                this.previewType = 'image';
            } else if(next.type.indexOf('audio') > -1){
                this.previewType = 'audio';
            }
            this.localPreview = URL.createObjectURL(next);
        }
    }

    beforeUnmount(){
        URL.revokeObjectURL(this.localPreview);
    }

}
