import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/ricettario/:nodeIdentifier',
            component: () => import('./views/ricettario.vue'),
            meta: {
                hasBar: true
            }
        },
    ];
};