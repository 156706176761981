import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
import { StripeClient } from './Services';

declare const StripeUIPlugin: any;

export interface PaymentResult {
    customerId?: string;
    code?: string;
    message?: string;
    error?: string;
}

export interface PaymentConfig {
    publishableKey?: string;
    companyName?: string;
    customerId?: string;
    paymentIntent?: string;
    ephemeralKey?: string;
    appleMerchantId?: string;
    appleMerchantCountryCode?: string;
    mobilePayEnabled?: boolean;
}

export interface BillingConfig {
    billingEmail?: string;
    billingName?: string;
    billingPhone?: string;
    billingCity?: string;
    billingCountry?: string;
    billingLine1?: string;
    billingLine2?: string;
    billingPostalCode?: string;
    billingState?: string;
}


class _StripeServices {
    
    makePayment(paymentConfig: OM.StripeClientPaymentConfigVm): Promise<PaymentResult> {
        return new Promise((resolve, reject) => {
            if (!window.cordova){
                reject("not_cordova");
                return;
            }
            
            // const paymentConfig: PaymentConfig = {
            //     publishableKey: stripePublicKey,
            //     companyName: "",
            //     paymentIntent: paymentIntent,
            //     customerId: "",
            //     ephemeralKey: "",
            //     appleMerchantId: "",
            //     appleMerchantCountryCode: "",
            //     mobilePayEnabled: true
            // }
            StripeUIPlugin.presentPaymentSheet(paymentConfig, null, (success: any) => {
                try {
                    const result = JSON.parse(success) as PaymentResult;
                    resolve(result);
                } catch (unused) {
                    resolve(success);
                }
                return;
            }, (error: any) => {
                reject(error); 
                return;
            });

        });
    }

}

export let StripeServices = new _StripeServices();

