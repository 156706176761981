import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-4" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-6 mb-3" }
const _hoisted_7 = { class: "box mb-3" }
const _hoisted_8 = { class: "boxTitle" }
const _hoisted_9 = { class: "box" }
const _hoisted_10 = { class: "boxTitle" }
const _hoisted_11 = { class: "col-sm-6 mb-3" }
const _hoisted_12 = { class: "box" }
const _hoisted_13 = { class: "boxTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_accordion = _resolveComponent("accordion")!
  const _component_fiscal_data = _resolveComponent("fiscal-data")!
  const _component_contact_data = _resolveComponent("contact-data")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Ruolo",
              text: "Ruolo"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.role) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rolesList, (item, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: item
              }, _toDisplayString(item), 9, _hoisted_4))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.user.role]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_accordion, null, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Dati personali",
                  text: "Dati personali"
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_personal_data, {
                item: _ctx.user.personalData
              }, null, 8, ["item"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_accordion, null, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Dati fiscali",
                  text: "Dati fiscali"
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_fiscal_data, {
                modelValue: _ctx.user.fiscalData,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.fiscalData) = $event)),
                user: _ctx.user
              }, null, 8, ["modelValue", "user"])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_accordion, null, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Dati di contatto",
                  text: "Dati di contatto"
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_contact_data, {
                item: _ctx.user.contactData
              }, null, 8, ["item"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}