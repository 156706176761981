import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_input = _resolveComponent("localized-input")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_sesso = _resolveComponent("sesso")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_localized_input, {
      labelKey: "Nome",
      labelValue: "Nome",
      placeholderKey: "Nome",
      placeholderValue: "Nome",
      modelValue: _ctx.item.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_localized_input, {
      labelKey: "Cognome",
      labelValue: "Cognome",
      placeholderKey: "Cognome",
      placeholderValue: "Cognome",
      modelValue: _ctx.item.surname,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.surname) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Sesso",
          text: "Sesso"
        })
      ]),
      _createVNode(_component_sesso, {
        modelValue: _ctx.item.sesso,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.sesso) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}