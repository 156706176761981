
import * as OM from '@/Model';
import store from '@/store';
import { awsUrl } from '@/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import fullImageModal from '@/components/fullImageModal.vue';

@Options({})
export default class ChatMessage extends Vue {
    nowstamp: number = new Date().getTime();
    userId: string = store.state.loginData.userIdentifier;
    awsUrl: string = awsUrl;
    
    @Prop({
        type: [Object, OM.ChatMessage]
    }) message: OM.ChatMessage;

    mounted(){
        let container: any = this.$refs.messageContainer;
        let imgs = container.querySelectorAll('._chatImg');
        imgs.forEach(x => {
            x.addEventListener('click', (ev) => {
                // if(!window.cordova){
                //     return;
                // }
                var background = getComputedStyle(ev.target).backgroundImage;
                var gradientIndex = background.indexOf(', linear-gradient')
                let url = "";
                if(gradientIndex > -1 ){
                    url = background.substring(5, gradientIndex - 2);
                } else {
                    url = background.substring(5, background.length -2);
                }
                this.$opModal.show(fullImageModal, {
                    imgPreview: url
                })
                // PhotoViewer.show(url, '');
            });
        })
    }

    timer: any = null;
    checkLongPress(){
        var touchduration = 400;
        this.timer = setTimeout(() => {
            this.$emit('longtouch', this.message);
        }, touchduration);
    }
    voidLongPress(){
        clearTimeout(this.timer);
    }
}
