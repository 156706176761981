
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { AcquistoClienteClient, LeadClient, OffertaClient, PayPalClient, ShopCategoryClient, StripeClient } from '@/services/Services';
import { Prop } from "vue-property-decorator";
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import store from '@/store';

@Options({})
export default class selectAndBuyOffers extends Vue {

    @Prop() offers: OM.Offerta[];

    isCordova: boolean = !!window.cordova;

    selectedOffer: OM.Offerta = null;
    shopCategory: OM.ShopCategoryDetailsVm = null;
    metodoPagamento: string = "";
    fiscalDataOk: boolean = false;

    tempMail: string = "";
    modificaMail: boolean = false;

    showMegaSpinner: boolean = false;
    paymentDone: boolean = false;
    
    acquistiInAttesa: OM.AcquistoClienteVmWithOffers[] = [];

    created(){
        AcquistoClienteClient.getAcquistiInAttesa(store.state.user.identifier)
        .then(x => {
            this.acquistiInAttesa = x;
        })
        this.tempMail = this.$store.state.user.contactData.email;
        if(this.offers.length == 1){
            this.setOffer(this.offers[0]);
        }
        this.fiscalDataOk = false;
    }

    get canFiscalDataOk(){
        return !!this.$store.state.user.personalData.name &&
            !!this.$store.state.user.personalData.surname &&
            !!this.$store.state.user.personalData.sesso &&
            !!this.$store.state.user.fiscalData.codiceFiscale &&
            !this.$store.state.formErrors.codiceFiscale &&
            !!this.$store.state.user.fiscalData.dataNascita &&
            !!this.$store.state.user.fiscalData.luogoNascita &&
            this.canIndirizzoOk;
    }

    get canIndirizzoOk(){
        return !!this.$store.state.user.fiscalData.fiscalAddress.via &&
            !!this.$store.state.user.fiscalData.fiscalAddress.civico &&
            !!this.$store.state.user.fiscalData.fiscalAddress.citta &&
            !!this.$store.state.user.fiscalData.fiscalAddress.nazione &&
            !!this.$store.state.user.fiscalData.fiscalAddress.cap
    }

    fiscalDataNext(){
        Promise.all([
            LeadClient.updatePersonalData({
                identifier: this.$store.state.user.identifier, 
                data: this.$store.state.user.personalData
            }),
            LeadClient.updateFiscalData({
                identifier: this.$store.state.user.identifier, 
                data: this.$store.state.user.fiscalData
            })
        ])
        .then(x => {
            this.fiscalDataOk = true;
        })
    }


    saveMail(){
        LeadClient.saveContactEmail(this.$store.state.user.identifier, this.tempMail)
        .then(x => {
            this.$store.state.user.contactData.email = this.tempMail;
            this.modificaMail = false;
        });
    }

    setOffer(offer: OM.Offerta){
        if(this.offers.length == 1 && this.selectedOffer)
            return;
        if(this.paymentDone)
            return;

        this.shopCategory = null;
        this.selectedOffer = offer;
        ShopCategoryClient.getDetails(this.selectedOffer.shopCategoryIdentifier)
        .then(x => {
            this.shopCategory = x;
        })
    }

    get canStripe(){
        return this.shopCategory.metodiPagamento.some(x => x == OM.MetodoPagamento.Stripe)
    }
    
    get canBonifico(){
        return this.shopCategory.metodiPagamento.some(x => x == OM.MetodoPagamento.Bonifico)
    }

    intentCbStripe(){
        return StripeClient.createIntentFromOffer(this.selectedOffer.identifier, this.shopCategory.stripePublicKey)
    }

    sessionCbStripe(){
        return StripeClient.createSessionService(this.selectedOffer.identifier, this.shopCategory.stripePublicKey)
    }

    paymentConfigCbStripe(){
        return StripeClient.createClientPaymentConfig(this.selectedOffer.identifier, this.shopCategory.stripePublicKey)
    }
    
    intentCbPayPal(){
        return PayPalClient.createIntentFromOffer(this.selectedOffer.identifier, this.shopCategory.payPalClientId)
    }
    currencyCbPayPal(){
        return PayPalClient.getOfferCurrency(this.selectedOffer.identifier)
    }

    onPayCompleteCard(paymentId){
        OffertaClient.completeBuyOfferta({
            stripeToken: paymentId,
            stripePublicKey: this.shopCategory.stripePublicKey,
            identifier: this.selectedOffer.identifier,
            appleIapId: null,
            upsellOfferIdentifier: null,
            activateOnCliente: null
        })
        .then(x => {
            this.$emit('cardpaid');
            this.paymentDone = true;
        })
    }

    payBonifico(){
        this.showMegaSpinner = true;
        OffertaClient.buyOffertaBonifico({
            stripeToken: '',
            stripePublicKey: '',
            identifier: this.selectedOffer.identifier,
            appleIapId: null,
            upsellOfferIdentifier: null,
            activateOnCliente: null
        })
        .then(x => {
            this.$emit('bonificopaid');
            this.paymentDone = true;
        })
        .catch(err => {
            ModalServices.alertModal(
                LocalizationServices.GetLocalizedValue(err, err),
                LocalizationServices.GetLocalizedValue("Chiudi", "Chiudi")
            );
        })
        .finally(() => this.showMegaSpinner = false)
    }
};
