import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04e94ab1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imageDropSpace" }
const _hoisted_2 = { class: "dragSpace" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "drag-drop",
        onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dropImage && _ctx.dropImage(...args)), ["prevent"])),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
      }, [
        _createElementVNode("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createVNode(_component_localized_text, {
              localizedKey: "Seleziona file",
              text: "Seleziona file"
            })
          ], true)
        ]),
        _createElementVNode("input", {
          type: "file",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args))),
          ref: "fileInput",
          class: "hidden absolute"
        }, null, 544)
      ], 32)
    ])
  ]))
}