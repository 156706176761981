import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_input = _resolveComponent("localized-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_localized_input, {
      labelKey: "Facebook",
      labelValue: "Facebook",
      placeholderKey: "Facebook",
      placeholderValue: "Facebook",
      modelValue: _ctx.item.facebook,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.facebook) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_localized_input, {
      labelKey: "Instagram",
      labelValue: "Instagram",
      placeholderKey: "Instagram",
      placeholderValue: "Instagram",
      modelValue: _ctx.item.instagram,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.instagram) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_localized_input, {
      labelKey: "Twitter",
      labelValue: "Twitter",
      placeholderKey: "Twitter",
      placeholderValue: "Twitter",
      modelValue: _ctx.item.twitter,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.twitter) = $event))
    }, null, 8, ["modelValue"])
  ]))
}