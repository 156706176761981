export function EnumToList(inputEnum: any): { [key: string]: number | string } {
    let ris: any = {};
    for(var prop in inputEnum){
        if (typeof inputEnum[prop] === 'number') {
            ris[inputEnum[prop]] = prop.replace(/_/g, ' ');
        }
    }
    return ris;
}
class Enum {
    static GetAll(){
        let ris = [];
        for(let p in this){
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary(){
        let ris = {};
        for(let p in this){
            ris[p] = this[p];
        }
        return ris;
    }
}

export enum GruppoSanguigno
{
    Apiu = 1,
    Ameno,
    Bpiu,
    Bmeno,
    Zeropiu,
    Zeromeno,
    ABpiu,
    ABmeno
}

export enum Sesso
{
    Maschio = 1,
    Femmina
}

export enum SiNo
{
    Si = 1,
    No
}

export enum TipoAlimentazione
{
    Onnivoro = 1,
    Onnivoro_Senza_Pesce,
    Onnivoro_Senza_Formaggi,
    Vegetariano_Con_Uova_E_Pesce,
    Vegetariano_Senza_Pesce,
    Vegano,
    Intollerante_Al_Lattosio,
    MosaicoFit,
    MosaicoCeliachy
}

export enum DolceSalato
{
    Dolce = 1,
    Salato
}

export enum GestioneRabbia {
    Tieni_Tutto_Dentro = 1,
    Ne_Parli_Subito,
    Ne_Parli_In_Seguito_Con_Calma,
    Ti_Chiudi_In_Te_Stesso,
    Esplodi,
    Sei_Moderato
}

export enum PrimaSeconda {
    Prima = 1,
    Seconda
}

export class LeadStatus extends Enum
{
    // static DaFare: string = "Da fare";
    // static InAttesa: string = "In attesa";
    // static Acquisito: string = "Acquisito";
    // static Perso: string = "Perso";
    // static Cancellato: string = "Cancellato";
    static Nuovo: string = "Nuovo";
    static Regolare: string = "Regolare";
    static Ricaduto: string = "Ricaduto";
    static Cancellato: string = "Cancellato";
}

export class CommentType extends Enum
{
    static Assegnazione: string = "assegnazione";
    static InAttesa: string = "in_attesa";
    static Call: string = "call";
    static Messaggio: string = "messaggio";
    static Whatsapp: string = "whatsapp";
    static Commento: string = "commento";
    static DaFare: string = "da_fare";
    static Acquisito: string = "acquisito";
    static Perso: string = "perso";
    static Cancellato: string = "cancellato";
    static Inserimento: string = "inserimento";
    static Stato: string = "stato";
    static SubStato: string = "sub_stato";
}

export class LeadSubStatus extends Enum
{
    static InArrivo: string = "In arrivo";
    static Appuntamento: string = "Appuntamento";
    static Trattamento: string = "Trattamento";
    static Mese1: string = "Mese1";
    static Mese2: string = "Mese2";
    static Mese3: string = "Mese3";
    static Mese4: string = "Mese4";
    static Mese5: string = "Mese5";
    static Mese6: string = "Mese6";
    static Ricaduto: string = "Ricaduto";
    static ConclusionePositiva: string = "Conclusione Positiva";
}

export class UserRoles extends Enum
{
    static Lead: string = "Lead";
    static Cliente: string = "Cliente";
    static Collaboratore: string = "Collaboratore";
    static CentroFumo: string = "CentroFumo";
    static Manager: string = "Manager";
    static Admin: string = "Admin";
}

export enum MetodoPagamento {
    Carta_di_credito = 1,
    Bonifico,
    Manuale
}

export class ModalitaPagamentoUser extends Enum {
    static Bonifico: string = "Bonifico";
    static Paypal: string = "Paypal";
    static Revolut: string = "Revolut";
}

export class RoutesGroup extends Enum {
    static Performance: RoutesGroupConfig = { text: "Performance", order: 1 };
    static Gestione: RoutesGroupConfig = { text: "Gestione", order: 2 };
    static Marketing: RoutesGroupConfig = { text: "Marketing", order: 3 };
    static Configurazione: RoutesGroupConfig = { text: "Configurazione", order: 4 };
}
class RoutesGroupConfig {
    text: string;
    order: number;
}

export class ProductType extends Enum
{
    static Appuntamento: string = "appuntamento";
    static Spedibile: string = "spedibile";
    static Corso: string = "corso";
}

export class AppuntamentoStatus extends Enum
{
    static InAttesa: string = "In attesa";
    static Concluso: string = "Concluso";
    static Respinto: string = "Respinto";
    static Annullato: string = "Annullato";
}

export class CompletionStatus extends Enum
{
    static In_Attesa: string = "In attesa";
    static Completato: string = "Completato";
    static Annullato: string = "Annullato";
}

export class DayOfWeek extends Enum
{
    static Sunday: string = "0";
    static Monday: string = "1";
    static Tuesday: string = "2";
    static Wednesday: string = "3";
    static Thursday: string = "4";
    static Friday: string = "5";
    static Saturday: string = "6";
}

export class ModalitaCalcoloProvvigioni extends Enum
{
    static Illimitato: string = "Illimitato";
    static Mensile: string = "Mensile";
    static Trimestrale: string = "Trimestrale";
    static Semestrale: string = "Semestrale";
    static Annuale: string = "Annuale";
}

export class Regioni extends Enum {
    static Abruzzo: string = "Abruzzo";
    static Basilicata: string = "Basilicata";
    static Calabria: string = "Calabria";
    static Campania: string = "Campania";
    static EmiliaRomagna: string = "Emilia Romagna";
    static FriuliVeneziaGiulia: string = "Friuli Venezia Giulia";
    static Lazio: string = "Lazio";
    static Liguria: string = "Liguria";
    static Lombardia: string = "Lombardia";
    static Marche: string = "Marche";
    static Molise: string = "Molise";
    static Piemonte: string = "Piemonte";
    static Puglia: string = "Puglia";
    static Sardegna: string = "Sardegna";
    static Sicilia: string = "Sicilia";
    static Toscana: string = "Toscana";
    static TrentinoAltoAdige: string = "Trentino Alto Adige";
    static Umbria: string = "Umbria";
    static ValdAosta: string = "Val d'Aosta";
    static Veneto: string = "Veneto";
}

export class StatoContratto extends Enum
{
    static NonGenerato: string = "NonGenerato";
    static NonFirmato: string = "NonFirmato";
    static Firmato: string = "Firmato";
}

export class LeadStatoCommerciale extends Enum
{
    static DaContattare = "Da contattare";
    static Contattato = "Contattato";
    static Ricontattare = "Ricontattare";
    static FissatoAppuntamento = "Fissato appuntamento";
    static NumeroIrraggiungibile = "Numero irraggiungibile";
    static RichiestaRicontattoDaCliente = "Richiesta ricontatto da cliente";
}