
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';
import { InAppNotificationClient } from '@/services/Services';

@Options({})
export default class assenzaTreGiorni extends Vue {

    @Prop() notification: OM.AssenzaTreGiorni;
    
    setRead(){
        InAppNotificationClient.setRead(this.notification.identifier)
        .then(x => {
            this.$emit('read');
        });
    }

}
