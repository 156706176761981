
import * as OM from '@/Model';
import * as ENUM from '@/enum';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';

@Options({})
export default class userPaymentData extends mixins(WithModel) {

    declare localValue: OM.UserPaymentData;

    modalitaPagamentos: string[] = ENUM.ModalitaPagamentoUser.GetAll();

}
