
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'

@Options({})
export default class successModal extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop({
        default: 'OK'
    }) okText: string;
    @Prop() okCallback: () => Promise<any>;

    async ok(){
        if(this.okCallback){
            await this.okCallback();
        }
        this.$emit('close');
    }

}
