import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pulsantoniContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["pulsantone", { active: _ctx.localValue == 'M' }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setValue('M')))
    }, " M ", 2),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["pulsantone", { active: _ctx.localValue == 'F' }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setValue('F')))
    }, " F ", 2)
  ]))
}