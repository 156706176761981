
import { Prop, Watch } from "vue-property-decorator";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from "@/mixins/withModel";

@Options({})
export default class splittedDate extends mixins(WithModel) {

    giorno: number = null;
    mese: number = null;
    anno: number = null;

    giorniList: number[] = [];
    mesiList: number[] = [];
    anniList: number[] = [];

    created(){
        this.giorniList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
        this.mesiList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.anniList = [];
        let currentYear = new Date().getFullYear();
        for(var i = 1910; i < currentYear; i++){
            this.anniList.push(i);
        }

        this.splitDate();
    }

    @Watch('localValue')
    onLocalValueChange(next, prev){
        this.splitDate();
    }

    splitDate(){
        let date = new Date(this.modelValue);
        this.giorno = date.getDate();
        this.mese = date.getMonth() + 1;
        this.anno = date.getFullYear();
    }

    composeAndEmit(){
        let ris = new Date(this.anno, this.mese - 1, this.giorno);
        if(this.isValidDate(ris)){
            this.emitModel(ris.toISOString());
        } else {

        }
    }
    
    isValidDate(d: any) {
        return d instanceof Date && !isNaN(<any>d);
    }

}
