import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3de577d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "d-flex flex-column justify-content-between" }
const _hoisted_3 = { class: "bg-black" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "d-flex justify-content-around my-3 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.imgPreview,
          style: {"width":"100%","object-fit":"contain","max-height":"80vh"}
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "btn square btn-danger me-1",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Annulla",
            text: "Annulla"
          })
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn square btn-primary ms-1",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Invia",
            text: "Invia"
          })
        ])
      ])
    ])
  ]))
}