
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class authData extends Vue {

    @Prop() item: OM.AuthData;
    @Prop({
        default: false
    }) canEnableLogin: boolean;

    showPassword: boolean = false;

    created(){
        this.item.password = "";
    }

    showPasswordChange(){
        this.item.password = "";
        this.showPassword = !this.showPassword;
    }

}
