
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PinchZoom from 'pinch-zoom-js';

@Options({})
export default class fullImageModal extends Vue {

    @Prop() imgPreview: string;

    pz: PinchZoom = null;

    mounted(){
        let element: any = this.$refs.pinch;
        this.pz = new PinchZoom(element, {
            draggableUnzoomed: false
        });
    }
    
    beforeUnmount(): void {
        this.pz.destroy(); // Unmounts the zooming container and global event listeners
    }
    
}

