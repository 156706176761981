
import WithModel from "@/mixins/withModel";
import { mixins, Options } from "vue-class-component";


@Options({})
export default class FileInput extends mixins(WithModel) {

    onFileSelect(ev){
        let file = ev.target.files[0];
        this.emitModel(file);
    }
}

