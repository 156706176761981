
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CollaboratoreClient } from '@/services/Services';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class datiBaseUser extends Vue {
 
    @Prop() user: OM.BaseUser;
    @Prop({
        default: null
    }) roles: string[];

    rolesList: string[] = Enum.UserRoles.GetAll();

    created(){
        if(this.roles){
            this.rolesList = this.roles;
        }
    }

}

