import { StorageServices } from '@/services/StorageServices';
import { createStore } from 'vuex'
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';

let vuestore = {
    state: {
        loginData: <OM.LoginData>null,
        user: <OM.Lead>null,
        selectedLanguage: "",
        consts: {
            localizedValues: [],
            enabledLanguages: [],
        },
        showSpinner: 0,
        isMobile: false,
        newNotificationCount: 0,
        beforeNavCbs: [],
        afterNavCbs: [],
        systemCopy: copyToClipboard,
        hasNewMessages: false,
        notification: null,
        isCordova: false,
        notificationCallback: (payload: any) => {
            console.log("notification received", payload);
        },
        formErrors: <{ [key: string]: boolean }>{},
        backButtonFunction: null,
        openBrowser: ($event) => {
            let url;
            if($event.target){
                $event.preventDefault();
                url = $event.target.href;
            } else {
                url = $event;
            }
            if(!window.cordova){
                window.open(url, "_blank");
            } else {
                window.cordova.InAppBrowser.open(url, "_system");
            }
        },
        isLead: (role?: string) => {
            let checkWith = role ? role : vuestore.state.user && vuestore.state.user.role;
            return checkWith == Enum.UserRoles.Lead;
        },
        isCliente: (role?: string) => {
            let checkWith = role ? role : vuestore.state.user && vuestore.state.user.role;
            return checkWith == Enum.UserRoles.Cliente;
        },
        isCollaboratore: (role?: string) => {
            let checkWith = role ? role : vuestore.state.user && vuestore.state.user.role;
            return checkWith == Enum.UserRoles.Collaboratore;
        },
        isCentro: (role?: string) => {
            let checkWith = role ? role : vuestore.state.user && vuestore.state.user.role;
            return checkWith == Enum.UserRoles.CentroFumo;
        },
        isManager: (role?: string) => {
            let checkWith = role ? role : vuestore.state.user && vuestore.state.user.role;
            return checkWith == Enum.UserRoles.Manager; 
        },
        isAdmin: (role?: string) => {
            let checkWith = role ? role : vuestore.state.user && vuestore.state.user.role;
            return checkWith == Enum.UserRoles.Admin;
        },
    }
}

vuestore.state.isCordova = !!window.cordova;
vuestore.state.isMobile = window.innerWidth <= 768;
window.addEventListener('resize', () => {
    store.state.isMobile = window.innerWidth <= 768;
})

let store = createStore(vuestore);
export default store


function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    window.toastr.success("Copiato!");
}