import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/storico',
            name: 'storico',
            component: () => import("./views/storico.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/misure',
            name: 'misure',
            component: () => import("./views/misure.vue"),
        },
        {
            path: '/storicoPianoAlimentare',
            name: 'storicoPianoAlimentare',
            component: () => import("./views/storicoPianoAlimentare.vue"),
        },
        {
            path: '/storicoPianoAlimentare/:identifier',
            name: 'storicoPianoAlimentareDetail',
            component: () => import("./views/storicoPianoAlimentareDetail.vue"),
        },
    ];
};