import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/img/virgola.svg'


const _hoisted_1 = {
  key: 0,
  class: "quotedMessage"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "timestamp" }
const _hoisted_5 = {
  key: 1,
  class: "virgola",
  src: _imports_0
}
const _hoisted_6 = {
  key: 2,
  class: "virgola",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.message.senderIdentifier == _ctx.userId ? 'right' : 'left')
  }, [
    _createElementVNode("div", {
      class: "chatMessage",
      onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkLongPress && _ctx.checkLongPress(...args))),
      onTouchmove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.voidLongPress && _ctx.voidLongPress(...args))),
      onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.voidLongPress && _ctx.voidLongPress(...args)))
    }, [
      (_ctx.message.quotedMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", {
              class: _normalizeClass(["sender", {'text-blue': _ctx.message.quotedMessage.fromBackend}])
            }, _toDisplayString(_ctx.message.quotedMessage.sender), 3),
            _createElementVNode("span", {
              class: "ellipsis",
              innerHTML: _ctx.message.quotedMessage.text
            }, null, 8, _hoisted_2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "chatImg",
        style: _normalizeStyle('background-image: url(' + _ctx.awsUrl + _ctx.message.senderIdentifier + '), url(' + _ctx.awsUrl + 'defaultUser.png)')
      }, null, 4),
      _createElementVNode("p", {
        class: _normalizeClass(["sender", {'text-blue': _ctx.message.fromBackend}])
      }, _toDisplayString(_ctx.message.sender), 3),
      _createElementVNode("span", {
        class: "messageContainer",
        innerHTML: _ctx.message.text,
        ref: "messageContainer"
      }, null, 8, _hoisted_3),
      _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.$filters.date(_ctx.message.timestamp, 'DD-MM-YYYY HH:mm')), 1),
      (_ctx.message.senderIdentifier == _ctx.userId)
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : (_openBlock(), _createElementBlock("img", _hoisted_6))
    ], 32)
  ], 2))
}