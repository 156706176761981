import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/acquisti',
            name: 'acquisti',
            component: () => import("./views/acquisti.vue"),
            meta: {
                hasBar: true
            }
        },
    ];
};