import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import router from '@/router';
import store from '@/store';
import { JsonClone } from '@/utils';
import { AuthServices } from './AuthServices';
import { AcquistoClienteClient, LeadClient, PagamentoBonificoClient, PrenotazioneClient, TicketPrenotazioneClient } from './Services';

class _LoginServices {
    
    loginCallback(loginData: OM.LoginData){
        store.state.loginData = loginData;
        StorageServices.setLoginData(loginData);
        CommonServices.setAuthToken(loginData.token);

        return Promise.all([
            PrenotazioneClient.getByCliente(loginData.userIdentifier),
            TicketPrenotazioneClient.getAvailableTickets(loginData.userIdentifier),
            LeadClient.getById(loginData.userIdentifier),
            AcquistoClienteClient.getAcquistiInAttesa(loginData.userIdentifier)
        ])
        .then(xs => {
            let hasPrenotato = xs[0].findIndex(x => x.stato.value != Enum.AppuntamentoStatus.Respinto && x.stato.value != Enum.AppuntamentoStatus.Annullato) > -1;
            let availableTickets = xs[1];
            store.state.user = xs[2];
            let acquistiInAttesa = xs[3];

            if(router.currentRoute.value.query.redirect){
                router.push(<string>router.currentRoute.value.query.redirect); 
            } else {
                if(store.state.isAdmin() || store.state.isCollaboratore() || store.state.isCliente()){
                    return xs;
                } else if(!hasPrenotato && availableTickets.length == 0 && acquistiInAttesa.length == 0){
                    router.push("/home");
                } else {
                    return xs;
                }
            }
        })
    }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            store.state.user = null;
            store.state.loginData = null;
            StorageServices.setLoginData(null);
            StorageServices.setNonFumatore(false);
            
            resolve();
        })
        return prom;
    }

}

export let LoginServices = new _LoginServices();