import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a9a6487"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  class: "settimanaVideoPlayer",
  controls: "",
  playsinline: ""
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "mb-3",
          innerHTML: _ctx.item.description
        }, null, 8, _hoisted_2),
        _createElementVNode("video", _hoisted_3, [
          (_ctx.item.url)
            ? (_openBlock(), _createElementBlock("source", {
                key: 0,
                src: _ctx.item.url
              }, null, 8, _hoisted_4))
            : (_ctx.item.attachment && _ctx.item.attachment.publicUrl)
              ? (_openBlock(), _createElementBlock("source", {
                  key: 1,
                  src: _ctx.item.url
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}