import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/corsi',
            name: 'corsi',
            component: () => import("./views/corsi.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/corsi/:identifier',
            name: 'corsiDetail',
            component: () => import("./views/corsiDetail.vue"),
            meta: {
                hasBar: false
            }
        }
    ];
};