import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_input = _resolveComponent("localized-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_localized_input, {
      labelKey: "Intestatario",
      labelValue: "Intestatario",
      placeholderKey: "Intestatario",
      placeholderValue: "Intestatario",
      modelValue: _ctx.item.intestatario,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.intestatario) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_localized_input, {
      labelKey: "Nome banca",
      labelValue: "Nome banca",
      placeholderKey: "Nome banca",
      placeholderValue: "Nome banca",
      modelValue: _ctx.item.bankName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.bankName) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_localized_input, {
      labelKey: "Swift",
      labelValue: "Swift",
      placeholderKey: "Swift",
      placeholderValue: "Swift",
      modelValue: _ctx.item.swift,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.swift) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_localized_input, {
      labelKey: "Iban",
      labelValue: "Iban",
      placeholderKey: "Iban",
      placeholderValue: "Iban",
      modelValue: _ctx.item.iban,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.iban) = $event))
    }, null, 8, ["modelValue"])
  ]))
}