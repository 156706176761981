import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/shop',
            name: 'shop',
            component: () => import("./shop.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/shop/:categoryIdentifier',
            name: 'shopCategory',
            component: () => import("./shopCategory.vue"),
        },
    ];
};