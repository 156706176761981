
import WithModel from '@/mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class GoogleAutocomplete extends mixins(WithModel) {
    
    @Prop({
        default: 1
    }) tabindex: number;

    @Prop({
        default: ""
    }) addressLocal: string;

    @Prop({
        default: 0
    }) asterisks: number;

    localPlaceholder: string = "";

    inputEl: any = null;
    autocomplete: any;

    @Watch("localValue")
    addressChanged() {
        this.emitModel(this.localValue);
    }

    mounted(){
        this.inputEl = this.$refs.autocomplete;
        // Create the autocomplete object, restricting the search to geographical
        // location types.
        this.autocomplete = new google.maps.places.Autocomplete(this.inputEl, { });
        (<any>window).puzzo = this.autocomplete;

        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        this.autocomplete.addListener('place_changed', (data) => {
            let place = this.autocomplete.getPlace();
            this.emitModel(place);
            // this.$emit('update:modelValue', place);
        });
        
        // navigator.geolocation.getCurrentPosition((position) => {
        //     var geolocation = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude
        //     };
        //     var circle = new google.maps.Circle({
        //         center: geolocation,
        //         radius: position.coords.accuracy
        //     });
        //     this.autocomplete.setBounds(circle.getBounds());
        // });

        setTimeout(() => {
            this.inputEl.setAttribute("autocomplete", "chrome-off");
        }, 300);
    }


    // emitInput(text) {
    //     this.$emit('changeAddress', text.data)
    // }

}
