
import Chart from 'chart.js/auto';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Options({})
export default class LineChart extends Vue {
    
    @Prop({
        default: () => []
    }) items: OM.DateValueOfDecimal[];
    @Prop() label: string;
    @Prop() label2: string;
    @Prop({
        default: '#249059'
    }) color: string;
    @Prop({
        default: '#249059'
    }) color2: string;
    @Prop() maxy: number;

    chart: any;
    mounted(){
        this.chartIt();
    }

    @Watch('items')
    onDataChange(next, prev){
        this.chart.destroy();
        this.chartIt();
    }

    chartIt(){
        let ctx: any = (<any>this.$refs.chart);
        let items: any[] = this.items.map(x => {
            return {
                x: new Date(x.date).getTime(),
                y: x.value
            }
        })
        let dataSets = [];
        if(items.length > 0){
            dataSets.push({
                label: this.label,
                data: items,
                fill: false,
                borderColor: this.color,
                backgroundColor: this.color,
                tension: 0
            });
        } 
        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: dataSets,
                labels: items.map(x => new Date(x.x).toISOString()),
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 15
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title: (context) => {
                                return moment((<any>context[0].raw).x).format('DD MMMM YYYY')
                            }
                        }
                    }
                },
                animation: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            displayFormats: {
                                day: 'DD/MM'
                            }
                        },
                        title: {
                            display: false,
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    },
                },
            },
        });
    }

}

