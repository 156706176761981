import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/questionari',
            name: 'questionari',
            component: () => import("./views/questionari.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/questionario/:professioneIdentifier',
            name: 'questionario',
            component: () => import("./views/questionario.vue"),
            meta: {
                hasBar: true
            }
        },
    ];
};