import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "intro"
}
const _hoisted_2 = {
  key: 1,
  class: "appPageContainer"
}
const _hoisted_3 = {
  key: 0,
  class: "appMode"
}
const _hoisted_4 = {
  key: 0,
  class: "spinnerAll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mega_spinner = _resolveComponent("mega-spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_mini_spinner = _resolveComponent("mini-spinner")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_mega_spinner)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.appMode != 'production')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Modalità: " + _toDisplayString(_ctx.appMode), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    (_ctx.$store.state.showSpinner > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_mini_spinner)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_modals_container)
  ], 64))
}