
import WithModel from '@/mixins/withModel';
import * as OM from '@/Model'
import { ModalServices } from '@/services/ModalServices';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as utils from '@/utils';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class fiscalData extends mixins(WithModel) {

    declare localValue: OM.FiscalData;
    codFiscError: boolean = false;
    codFiscNonCoerente: boolean = false;
    
    @Prop() user: OM.BaseUser;

    startScan(){
        window.cordova.plugins.barcodeScanner.scan(
            (x) => {
                if(x.text){
                    this.localValue.codiceFiscale = x.text;
                    this.checkValidityCodiceFiscale();
                }
            },
            (err) => {
                ModalServices.alertModal('', err);
            }
        )
    }

    @Watch('user.personalData.name')
    onNameChange(next, prev){
        if(this.localValue.codiceFiscale && this.localValue.codiceFiscale.length == 16)
            this.checkValidityCodiceFiscale();
    }

    @Watch('user.personalData.surname')
    onSurnameChange(next, prev){
        if(this.localValue.codiceFiscale && this.localValue.codiceFiscale.length == 16)
            this.checkValidityCodiceFiscale();
    }

    @Watch('user.personalData.sesso')
    onSessoChange(next, prev){
        if(this.localValue.codiceFiscale && this.localValue.codiceFiscale.length == 16)
            this.checkValidityCodiceFiscale();
    }

    @Watch('localValue.codiceFiscale')
    onCodiceFiscaleChange(next, prev){
        if(this.localValue.codiceFiscale && this.localValue.codiceFiscale.length == 16)
            this.checkValidityCodiceFiscale();
        else
            this.$store.state.formErrors.codiceFiscale = true;
    }

    checkValidityCodiceFiscale(){
        this.$store.state.formErrors.codiceFiscale = false;
        this.codFiscError = false;
        try {
            let inverso = utils.CodFiscInverso(this.localValue.codiceFiscale);
            this.localValue.dataNascita = new Date(inverso.year, inverso.month - 1, inverso.day).toISOString();
            this.user.personalData.sesso = inverso.gender;
            this.localValue.luogoNascita = inverso.birthplace;
            
            let calculated = utils.CalcolaCodFisc(this.user.personalData.name, this.user.personalData.surname, (<any>this.user.personalData.sesso), inverso.day, inverso.month, inverso.year, inverso.birthplace, inverso.birthplaceProvincia);
            if(calculated.cf == inverso.cf){
                this.$store.state.formErrors.codiceFiscale = false;
                this.codFiscNonCoerente = false;
            } else {
                this.$store.state.formErrors.codiceFiscale = true;
                this.codFiscNonCoerente = true;
            }
        } catch(ex){
            this.codFiscError = true;
            this.$store.state.formErrors.codiceFiscale = true;
        }
    }

    alertLuogoDataNascita(){
        ModalServices.alertModal(
            '',
            LocalizationServices.GetLocalizedValue('Luogo e data di nascita vengono compilati tramite il codice fiscale', 'Luogo e data di nascita vengono compilati tramite il codice fiscale')
        )
    }

}
