import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/wallet',
            name: 'wallet',
            component: () => import("./views/wallet.vue"),
            meta: {
                hasBar: true
            }
        }
    ];
};