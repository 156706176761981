
import * as OM from '@/Model';
// import { StripePublishableKey } from '@/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { StripeServices } from '@/services/StripeServices';

@Options({})
export default class stripeCheckout extends Vue {

    @Prop() userName: string;
    @Prop() configCb: () => Promise<OM.StripeClientPaymentConfigVm>;
    @Prop() intentCb: () => Promise<string>;
    @Prop({
        required: true
    }) stripeKey: string;

    completed: boolean = false;
    paying: boolean = false;
    elementsReady: boolean = false;

    stripe: any;
    checkout: any;
    paymentConfig: OM.StripeClientPaymentConfigVm = null;
    clientSecret: string = "";
    error: string = "";

    paymentUrl: string = "";

    get isTestMode(){
        return this.stripeKey.indexOf('pk_test') > -1
    }

    created(){
        this.stripe = Stripe(this.stripeKey);
        if(window.cordova){
            this.configCb()
            .then(x => {
                this.paymentConfig = x;
            })
        } else {
            this.intentCb()
            .then(x => {
                this.paymentUrl = x;
            })
        }
    }

    go(){
        if(window.cordova){
            StripeServices.makePayment(this.paymentConfig)
            .then(x => {
                if(x.code == '0')
                    this.$emit('complete', this.paymentConfig.paymentIntentId);
            })
            .catch(err => {
                console.log(err);
            })
        } else {
            this.$store.state.openBrowser(this.paymentUrl);
        }
    }

}

