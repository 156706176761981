
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class BarreRisultato extends Vue {

    @Prop() vm: OM.UserObiettivoCardVm;
    
    targetWidth: string = "width: 0%;";
    resultWidth: string = "width: 0%;";
    risultatoPerc: number = 0;
    isOver: boolean = false;

    created(){
        this.calc();
    }

    calc(){
        if(this.vm.obiettivo == 0){
            this.targetWidth = "width: auto;";
            this.resultWidth = "width: auto;";
            this.risultatoPerc = 0;
            return;
        }
        setTimeout(() => {
            this.isOver = false;
            this.risultatoPerc = this.vm.risultato * 100 / this.vm.obiettivo;
            if(this.risultatoPerc > 100){
                let targetPerc = this.vm.obiettivo * 100 / this.vm.risultato;
                this.resultWidth = `width: 100%;`;
                this.targetWidth = `width: ${targetPerc}%;`;
                this.isOver = true;
                this.risultatoPerc = this.risultatoPerc - 100;
            } else {
                this.targetWidth = `width: 100%;`;
                this.resultWidth = `width: ${this.risultatoPerc}%;`;
            }
        }, 50)
    }

}

