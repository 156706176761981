
import opTableLocal from '@/components/opTable/opTableLocal.vue';
import opTableServerSide from '@/components/opTable/opTableServerSide.vue';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { markRaw } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
        opTableLocal,
        opTableServerSide
    }
})
export default class OpTable extends Vue {
    @Prop() items: any[];
    @Prop({
        default: true
    }) showUpperPagination: boolean;
    @Prop({
        default: true
    }) showLowerPagination: boolean;
    @Prop({
        type: Number,
        default: 10
    }) itemsPerPage: number;
    @Prop({
        required: false
    }) filter: any;
    @Prop() name: string;
    @Prop({ default: () => { return {} } }) filtersFn: any;
    @Prop({
        required: false
    }) totalItemsCount: number;
    @Prop() clearFilters: number;

    search(filter){
        this.$emit("search", this.search)
    }

    component: any = null;

    opName: string = "";

    created(){
        this.opName = this.name || this.$route.path;

        if(this.filter){
            this.component = markRaw(opTableServerSide);
            let filters = StorageServices.getOpTableFilters(this.opName);
            for(let p in filters){
                this.filter[p] = filters[p];
            }
        } else {
            this.component = markRaw(opTableLocal);
        }

        store.state.beforeNavCbs.push((to, from, next) => {
            this.setFilters();
            next();
        })
         
        window.addEventListener('beforeunload', this.setFilters);
    }

    beforeDestroy(){
        this.setFilters();
        window.removeEventListener('beforeunload', this.setFilters);
    }

    setFilters(){
        if(this.filter){
            StorageServices.setOpTableFilters(this.opName, this.filter);
        } else {
            let opTableComponent = <any>this.$refs.opTableRef;
            if(!opTableComponent)
                return;
            let filters = opTableComponent.filters || opTableComponent.filterInputs;
            let toSave = {};
            filters.forEach(x => {
                toSave[x.name] = x.value;
            });
            StorageServices.setOpTableFilters(this.opName, toSave);
        }
    }



}
