import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/prenotazioni',
            name: 'prenotazioni',
            component: () => import("./views/prenotazioni.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/prenotazioneDetail/:identifier',
            name: 'prenotazioneDetail',
            component: () => import("./views/prenotazioneDetail.vue"),
        },
        {
            path: '/prenotazioni/new/:professioneIdentifier',
            name: 'prenotazioniNew',
            component: () => import("./views/prenotazioniNew.vue"),
        },
        {
            path: '/prenotazioni/new/:professioneIdentifier/giornoPrenotazione/:userIdentifier',
            name: 'giornoPrenotazione',
            component: () => import("./views/giornoPrenotazione.vue"),
        },
        {
            path: '/prenotazioni/modify/:professioneIdentifier/giornoPrenotazione/:userIdentifier/:prenotazioneIdentifier',
            name: 'giornoPrenotazioneModify',
            component: () => import("./views/giornoPrenotazione.vue"),
            meta: {
                isModify: true
            }
        },
        {
            path: '/appuntamentoRecap/:modificaPrenotazioneIdentifier?',
            name: 'appuntamentoRecap',
            component: () => import("./views/appuntamentoRecap.vue"),
        },
    ];
};