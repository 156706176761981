
import * as OM from '@/Model';
import StripeSavedCards from './stripeSavedCards.vue';
// import { StripePublishableKey } from '@/config';
import Toggler from '@/components/toggler.vue';
import { StripeClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
        StripeSavedCards,
        Toggler
    }
})
export default class stripeFormCard extends Vue {

    @Prop() userName: string;
    @Prop() intentCb: () => Promise<string>;
    @Prop({
        required: true
    }) stripeKey: string;

    showNewCard: boolean = false;
    paid: boolean = false;
    paying: boolean = false;
    cards: OM.StripeCardDetailVm[] = null;
    
    selectedCard: string = "";

    saveCard: boolean = false;

    stripe: any;
    card: any;
    cardholderName: string = "";
    clientSecret: string = "";

    get isTestMode(){
        return this.stripeKey.indexOf('pk_test') > -1
    }

    setShowNewCard(){
        this.showNewCard = true;
        this.selectedCard = "";
        this.$nextTick(() => {
            this.mountStripe();
        });
    }

    created(){
        StripeClient.getSavedCards(this.stripeKey)
        .then(x => {
            this.cards = x;
            if(this.cards.length == 0){
                this.setShowNewCard();
            }
        })
        // this.stripe = Stripe(StripePublishableKey);
        this.stripe = Stripe(this.stripeKey);
        this.intentCb()
        .then(x => {
            this.clientSecret = x;
        })
    }

    mountStripe(){
        this.showNewCard = true;
        this.selectedCard = '';
        var elements = this.stripe.elements();
        var style = {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        };
        this.card = elements.create("card", { hidePostalCode: true, style: style });
        this.card.mount(this.$refs.cardelement);
        this.card.addEventListener('change', ({error}) => {
            const displayError = this.$refs.carderrors as HTMLElement;
            if (error) {
                displayError.textContent = error.message;
            } else {
                displayError.textContent = '';
            }
        });
    }

    get canPay(){
        return !!this.clientSecret && this.cardholderName.trim();
    }

    pay(){
        this.paying = true;
		if(this.selectedCard != "") {
            this.confirmCardPayment(this.selectedCard);
		} else {
            this.stripe.createToken(this.card)
            .then(result => {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = this.$refs.carderrors as HTMLElement;
                    errorElement.textContent = result.error.message;
                    this.paying = false;
                } else {
                    this.confirmCardPayment({
                        card: {
                            token: result.token.id
                        },
                        billing_details: {
                            name: this.userName,
                        },
                    })
                }
            })
		}
		
    }

    confirmCardPayment(payment_method){
        this.stripe.confirmCardPayment(this.clientSecret, {
            payment_method: payment_method,
        })
        .then((paymentResult) => {
            if(paymentResult.error){
                var errorElement = this.$refs.carderrors as HTMLElement;
                errorElement.textContent = paymentResult.error.message;
                this.paying = false;
            } else {
                this.paying = false;
                this.$emit('complete', paymentResult.paymentIntent.id);
                if(this.saveCard){
                    this.addCardToCustomer();
                }
            }
        });
    }

    addCardToCustomer(){
		this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
                name: this.cardholderName,
            }
        })
        .then((result) => {
            if (result.error) {
                alert(result.error);
            } else {
                StripeClient.addCardToCustomer(result.paymentMethod.id, this.stripeKey)
            }
        });
    }

}

