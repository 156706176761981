import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_input = _resolveComponent("localized-input")!
  const _component_telephone = _resolveComponent("telephone")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.isAdmin() || _ctx.$store.state.isManager())
      ? (_openBlock(), _createBlock(_component_localized_input, {
          key: 0,
          labelKey: "Nome contatto (visibile solo ai manager)",
          labelValue: "Nome contatto (visibile solo ai manager)",
          placeholderKey: "Nome contatto (visibile solo ai manager)",
          placeholderValue: "Nome contatto (visibile solo ai manager)",
          modelValue: _ctx.localValue.contactName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.contactName) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_localized_input, {
      labelKey: "Email di contatto",
      labelValue: "Email di contatto",
      placeholderKey: "Email di contatto",
      placeholderValue: "Email di contatto",
      modelValue: _ctx.localValue.email,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.email) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_telephone, {
      modelValue: _ctx.localValue.telephone,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.telephone) = $event))
    }, null, 8, ["modelValue"])
  ]))
}