import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/clienti',
            name: 'clienti',
            component: () => import("./views/clienti.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/clienti/:identifier',
            name: 'clientiDetail',
            component: () => import("./views/clientiDetail.vue"),
            meta: {
                hasBar: false
            }
        },
        {
            path: '/clienti/:identifier/contatti',
            name: 'contattiDetail',
            component: () => import("./views/contattiDetail.vue"),
            meta: {
                hasBar: false
            }
        },
    ];
};