import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-065041c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalBody p-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "text-center mt-5" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "text-center mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "text-center",
        innerHTML: _ctx.text
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          class: "btn roundedButton btn-primary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args))),
          disabled: _ctx.timer > 0
        }, [
          (_ctx.timer > 0)
            ? (_openBlock(), _createBlock(_component_localized_text, {
                key: 0,
                localizedKey: "(Attendi: {{timer}})",
                text: "(Attendi: {{timer}})",
                object: { timer: _ctx.timer }
              }, null, 8, ["object"]))
            : _createCommentVNode("", true),
          _createTextVNode(),
          _createVNode(_component_localized_text, {
            localizedKey: "CONFERMO",
            text: "CONFERMO"
          })
        ], 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "btn roundedButton btn-danger",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "ANNULLA",
            text: "ANNULLA"
          })
        ])
      ])
    ])
  ]))
}