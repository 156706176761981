import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-331c4116"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["type", "tabindex", "readonly"]
const _hoisted_3 = ["type", "tabindex", "min", "max", "step", "readonly"]
const _hoisted_4 = ["type", "tabindex", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!
  const _directive_date = _resolveDirective("date")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelKey)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 0,
          type: "label",
          localizedKey: _ctx.labelKey,
          text: _ctx.labelValue,
          prevText: _ctx.labelPrevText,
          afterText: _ctx.labelAfterText
        }, null, 8, ["localizedKey", "text", "prevText", "afterText"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'date')
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          ref: "input",
          class: "form-control",
          type: _ctx.type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          tabindex: _ctx.tabIndex,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeModel())),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus'))),
          readonly: _ctx.readonly
        }, null, 40, _hoisted_2)), [
          [_vModelDynamic, _ctx.localValue],
          [_directive_localizedPlaceholder, { 
                key: _ctx.placeholderKey, 
                value: _ctx.placeholderValue, 
                prevText: _ctx.placeholderPrevText, 
                afterText: _ctx.placeholderAfterText 
            }],
          [_directive_date, _ctx.localValue]
        ])
      : (_ctx.type == 'number')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            ref: "input",
            class: "form-control",
            type: _ctx.type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue) = $event)),
            tabindex: _ctx.tabIndex,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeModel())),
            onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('focus'))),
            min: _ctx.min,
            max: _ctx.max,
            step: _ctx.step,
            readonly: _ctx.readonly
          }, null, 40, _hoisted_3)), [
            [_vModelDynamic, _ctx.localValue],
            [_directive_localizedPlaceholder, { 
                key: _ctx.placeholderKey, 
                value: _ctx.placeholderValue, 
                prevText: _ctx.placeholderPrevText, 
                afterText: _ctx.placeholderAfterText 
            }]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 3,
            ref: "input",
            class: "form-control",
            type: _ctx.type,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localValue) = $event)),
            tabindex: _ctx.tabIndex,
            onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeModel())),
            onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('focus'))),
            readonly: _ctx.readonly
          }, null, 40, _hoisted_4)), [
            [_vModelDynamic, _ctx.localValue],
            [_directive_localizedPlaceholder, { 
                key: _ctx.placeholderKey, 
                value: _ctx.placeholderValue, 
                prevText: _ctx.placeholderPrevText, 
                afterText: _ctx.placeholderAfterText 
            }]
          ])
  ]))
}