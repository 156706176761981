
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class accordion extends Vue {
    @Prop() open: boolean;
    @Prop({
        default: false
    }) indent: boolean;
    openThis: boolean = false;
    created(){
        if(this.open){
            this.openThis = true;
        }
    }
}

