import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'begin',
            component: () => import("./views/begin.vue"),
            meta: {
                allowAnonymous: true,
                closeAppModal: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("./views/login.vue"),
            meta: {
                allowAnonymous: true
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () => import("./views/register.vue"),
            meta: {
                allowAnonymous: true
            }
        },
        {
            path: '/reset_password',
            name: 'reset_password',
            component: () => import("./views/resetPassword.vue"),
            meta: {
                allowAnonymous: true
            }
        },
        {
            path: '/change_password',
            name: 'change_password',
            component: () => import("./views/changePassword.vue"),
            meta: {
                allowAnonymous: true
            }
        },
        {
            path: '/paymentCompleted',
            name: 'paymentCompleted',
            component: () => import("./views/paymentCompleted.vue"),
            meta: {
                allowAnonymous: true
            }
        },
    ];
};